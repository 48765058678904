import { useSelector } from "hooks";

import {
  selectSelections,
  selectSelectionsCount,
  selectUnfilteredSelectionsCount,
  selectBetType,
  selectBetslipIsActive,
  selectBetslipIsConfirming,
  selectBetslipIsQuickDepositing,
  selectBetslipIsReviewingMessages,
  selectBetslipIsSubmitting,
  selectAcceptedCount,
  selectMultiAccepted,
  selectRejectedCount,
  selectCombinedSinglesPayout,
  selectCombinedSinglesStakeWithoutPromotions,
  selectCombinedOdds,
  selectOddsFormat,
  selectMultiStake,
  selectCombinedSinglesStake,
  selectMultiPayout,
  selectCanSubmitBet,
  selectUnfilteredSelections,
  selectBetslipIsDuplicate,
  selectBetslipHasAcceptedIsDuplicate,
  selectBetslipMessage,
  selectHasSPMarkets,
  selectBetslipIsIntercepted,
  // Actions
  setBetType,
  setStakeSingle,
  setStakeMulti,
  setMultiIsUsingPromo,
  removeAccepted,
  removeRejected,
  addSelection,
  removeSelection,
  clearSelections,
  changeOdds,
  setIsConfirming,
  setIsUsingPromo,
  submitBet,
  toggleSelection,
  clearAccepted,
  clearRejected,
  setBetSlipState,
  reuseSelection,
  clearBetslipMessage,
  selectIsAlmostSRM,
  selectCanBeSRM,
  convertSelectionsToSRM,
  selectRaceUrl,
  selectSemTypeIfSingleBetAndOnMultiTab,
  selectIsMultiUsingPromo,
  selectMultiRejected,
  selectMultiContainsAnOutright,
  selectBetId,
  selectCombinedMultiStake,
  selectCombinedOddsIncludingNewOdds,
} from "sections/Betting/Betslip/betslipSlice";
import { useCanBetslipDeposit } from "hooks/useCanBetslipDeposit";

export const useBetslip = () => {
  const selections = useSelector(selectSelections);
  const unfilteredSelectionsCount = useSelector(
    selectUnfilteredSelectionsCount,
  );
  const selectionsCount = useSelector(selectSelectionsCount);
  const semTypeIfSingleBetAndOnMultiTab = useSelector(
    selectSemTypeIfSingleBetAndOnMultiTab,
  );
  const betType = useSelector(selectBetType);
  const betslipIsActive = useSelector(selectBetslipIsActive);
  const betslipIsConfirming = useSelector(selectBetslipIsConfirming);
  const betslipIsDuplicate = useSelector(selectBetslipIsDuplicate);
  const betslipMessage = useSelector(selectBetslipMessage);
  const betslipHasAcceptedIsDuplicate = useSelector(
    selectBetslipHasAcceptedIsDuplicate,
  );

  const betslipIsQuickDepositing = useSelector(selectBetslipIsQuickDepositing);
  const betslipIsReviewingMessages = useSelector(
    selectBetslipIsReviewingMessages,
  );
  const betslipIsSubmitting = useSelector(selectBetslipIsSubmitting);
  const betslipIntercepted = useSelector(selectBetslipIsIntercepted);

  const acceptedCount = useSelector(selectAcceptedCount);
  const rejectedCount = useSelector(selectRejectedCount);
  const multiAccepted = useSelector(selectMultiAccepted);
  const multiRejected = useSelector(selectMultiRejected);
  const combinedOdds = useSelector(selectCombinedOdds);
  const combinedOddsIncludingNewOdds = useSelector(
    selectCombinedOddsIncludingNewOdds,
  );
  const multiStake = useSelector(selectMultiStake);
  const combinedSinglesPayout = useSelector(selectCombinedSinglesPayout);
  const multiPayout = useSelector(selectMultiPayout);
  const oddsFormat = useSelector(selectOddsFormat);
  const combinedSinglesStake = useSelector(selectCombinedSinglesStake);
  const hasSPMarkets = useSelector(selectHasSPMarkets);
  const hasMultiOutright = useSelector(selectMultiContainsAnOutright);
  const combinedSinglesStakeWithoutPromotion = useSelector(
    selectCombinedSinglesStakeWithoutPromotions,
  );
  const combinedMultiStake = useSelector(selectCombinedMultiStake);
  const canSubmitBet = useSelector(selectCanSubmitBet);
  const isAlmostSRM = useSelector(selectIsAlmostSRM);
  const canBeSRM = useSelector(selectCanBeSRM);
  const raceUrl = useSelector(selectRaceUrl);

  const isMultiUsingPromo = useSelector(selectIsMultiUsingPromo);

  const betId = useSelector(selectBetId);

  const stakeAll = useSelector((state) => state.betslip.stakeAll);
  const { offerDeposit, offerQuickDeposit, balances } = useCanBetslipDeposit();

  return {
    props: {
      ...balances,
      betId,
      stakeAll,
      isMultiUsingPromo,
      multiStake,
      betslipIsActive,
      betslipIsConfirming,
      betslipIsQuickDepositing,
      betslipIsReviewingMessages,
      betslipIsSubmitting,
      selections,
      betType,
      acceptedCount,
      selectionsCount,
      rejectedCount,
      multiAccepted,
      combinedOdds,
      combinedOddsIncludingNewOdds,
      combinedSinglesPayout,
      combinedSinglesStakeWithoutPromotion,
      oddsFormat,
      combinedSinglesStake,
      multiPayout,
      canSubmitBet,
      unfilteredSelectionsCount,
      offerQuickDeposit,
      offerDeposit,
      betslipIsDuplicate,
      betslipHasAcceptedIsDuplicate,
      betslipMessage,
      hasSPMarkets,
      betslipIntercepted,
      isAlmostSRM,
      canBeSRM,
      raceUrl,
      semTypeIfSingleBetAndOnMultiTab,
      multiRejected,
      hasMultiOutright,
      combinedMultiStake,
    },
    actions: {
      // Actions
      setBetType,
      setStakeSingle,
      setStakeMulti,
      setMultiIsUsingPromo,
      clearRejected,
      removeRejected,
      clearAccepted,
      removeAccepted,
      addSelection,
      removeSelection,
      clearSelections,
      changeOdds,
      setIsUsingPromo,
      setBetSlipState,
      reuseSelection,
      setIsConfirming,
      submitBet,
      toggleSelection,
      clearBetslipMessage,
      convertSelectionsToSRM,
    },
    toggleSelection,
  };
};

export const useSelections = () => {
  const selections = useSelector(selectUnfilteredSelections);

  return {
    props: {
      selections,
    },
    actions: {
      // Actions
      setBetType,
      setStakeSingle,
      setStakeMulti,
      setMultiIsUsingPromo,
      removeAccepted,
      removeRejected,
      addSelection,
      removeSelection,
      clearSelections,
      changeOdds,
      setIsUsingPromo,
      clearAccepted,
      clearRejected,
      submitBet,
      toggleSelection,
    },
    toggleSelection,
  };
};
