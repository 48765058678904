import ToggleGroup from "components/ToggleGroup";
import React, { useEffect } from "react";
import cx from "classnames";
import * as styles from "./RaceMeetingsFilter.module.scss";
import { ReactComponent as Australia } from "sections/Betting/Racing/components/assets/icon-australia.svg";
import { ReactComponent as World } from "sections/Betting/Racing/components/assets/icon-world.svg";
import { type RacingRouteParams, RegionType } from "../../hooks/useRacingRoute";
import { usePageContext } from "hooks/usePageContext";
import { useHub } from "hooks/graphql/useHubs";
import { isEqual } from "lodash";
import { usePermissions } from "hooks";
import { PermissionReason } from "utilities/Auth/authSlice";
import { TitleIcon } from "components/TitleIcon";

type TitleCountsType = Record<string, number>;

type RacesFilterProps = {
  titleCounts: TitleCountsType;
  route: Partial<RacingRouteParams>;
  setRouteValue: (value: Partial<RacingRouteParams>) => void;
  badgeLoading?: boolean;
};

export default ({
  titleCounts,
  setRouteValue,
  route,
  badgeLoading,
}: RacesFilterProps) => {
  const { hub: hubName } = usePageContext();
  const hubData = useHub(hubName);
  const sportsTitles = hubData?.titles ?? [];

  const permissions = usePermissions();

  useEffect(() => {
    const canSubmitInternationalRacingBet =
      permissions?.submitInternationalRacingBet === PermissionReason.GRANTED;

    if (
      route?.region.includes(RegionType.world) &&
      !canSubmitInternationalRacingBet
    ) {
      setRouteValue({ region: [RegionType.au] });
    }
  }, [permissions, route]);

  return (
    <div className={cx(styles.racesFilter)}>
      <ToggleGroup.Root>
        <ToggleGroup.List
          type="multiple"
          alignment="left"
          onClear={() => setRouteValue({ sports: undefined })}
          onValueChange={(value) =>
            setRouteValue({ sports: value as string[] })
          }
          value={route?.sports ?? []}
        >
          {sportsTitles.map((title) => {
            return (
              <ToggleGroup.Item
                type="button"
                value={title.slug}
                key={"title-" + title.slug}
                count={
                  route.tab === "next-to-jump" ? null : titleCounts[title.slug]
                }
                badgeLoading={badgeLoading}
              >
                <TitleIcon title={title} svg />
              </ToggleGroup.Item>
            );
          })}
        </ToggleGroup.List>

        <ToggleGroup.List
          type="multiple"
          alignment="right"
          onValueChange={(value) => {
            if (
              (route.region?.length === 1 && !isEqual(route.region, value)) ||
              route.region?.length === 2
            ) {
              setRouteValue({ region: value as RegionType[] });
            }
          }}
          value={route.region ?? []}
        >
          <ToggleGroup.Item value="au">
            <Australia />
          </ToggleGroup.Item>
          {permissions?.submitInternationalRacingBet ===
            PermissionReason.GRANTED && (
            <ToggleGroup.Item value="world">
              <World />
            </ToggleGroup.Item>
          )}
        </ToggleGroup.List>
      </ToggleGroup.Root>
    </div>
  );
};
