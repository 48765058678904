import cx from "classnames";
import type {
  EventMarket,
  Event as EventType,
} from "hooks/firestore/betting/useBetting";
import React, { type PropsWithChildren, type ReactNode } from "react";
import type {
  RaceCompetitorType,
  RaceEventType,
  RaceMarketsType,
} from "sections/Betting/Race/hooks/RacingTypes";
import { ErrorMessage } from "sections/Betting/components/ComplexBetBuilder/components/ErrorMessage";
import { SameEventMultiSummary } from "./components/SameEventMulti";
import { Slip } from "./components/Slip/Slip";
import { useComplexBuilderProps } from "./hook/useSameComplexBuilderProps";

import * as styles from "./ComplexBetBuilder.module.scss";

export type ComplexBetType = "srm" | "sgm" | "exotic";

export type ComplexBetBuilderProps = {
  selections: string[];
  handleRemoveSelection: (outcomeId: string) => void;
  handleRemoveAllSelections: () => void;
  errorMessage?: string;
  errorMessageToReplaceFooter?: boolean;
  type: ComplexBetType;
  event: EventType | RaceEventType;
  markets?: null | RaceMarketsType[] | EventMarket[];
  competitors?: null | RaceCompetitorType[];
  rollBackSelectionChange?: () => void;
  setErrorMessage?: (message: string) => void;
  dependencies?: any[];
  setLatestValidSelections?: (selections: string[]) => void;
  latestValidSelections?: string[];
  renderSelectionName?: (outcomeId: string) => ReactNode;
  errorMessageShouldAllowClear?: boolean;
  renderSlipInfo?: () => React.ReactNode;
};

export const ComplexBetBuilder = ({
  children,
  ...props
}: PropsWithChildren<ComplexBetBuilderProps>) => {
  const {
    isOpen,
    setIsOpen,
    isScrollMode,
    srmElement,
    slipStyles,
    isAtMostTablet,
    isLoggedIn,
    odds,
    loading,
    handleRemoveAllSelections,
    areAnySelectionsNotActive,
    addToBetSlip,
    selections,
    slipHeight,
    renderSlipInfo,
  } = useComplexBuilderProps(props);

  const containerStyles = {
    height: `${slipHeight}px`,
  };

  const shouldShowErrorMessage =
    (isScrollMode && !isAtMostTablet) || isAtMostTablet;

  return (
    <div ref={srmElement} style={containerStyles} className={styles.refElement}>
      <div
        className={cx(styles.container, {
          [styles.isScrollMode]: isScrollMode || isAtMostTablet,
        })}
        style={slipStyles}
      >
        {!props.errorMessageToReplaceFooter ? (
          <>
            {props.errorMessage && shouldShowErrorMessage && (
              <ErrorMessage
                errorMessage={props.errorMessage}
                type={props.type}
                variant="error"
              />
            )}

            {isOpen && (
              <div
                className={cx(styles.panel, {
                  [styles.isOpen]: isOpen,
                })}
              >
                {children}
              </div>
            )}

            <Slip
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isLoggedIn={isLoggedIn}
              odds={odds}
              loading={loading}
              handleRemoveAllSelections={handleRemoveAllSelections}
              addToBetSlip={addToBetSlip}
              areAnySelectionsNotActive={areAnySelectionsNotActive}
              selectionsLength={selections.length}
              renderSlipInfo={() => {
                if (typeof renderSlipInfo === "function") {
                  return renderSlipInfo();
                } else {
                  return (
                    <SameEventMultiSummary
                      odds={odds}
                      loading={loading}
                      isLoggedIn={isLoggedIn}
                      selectionsLength={selections.length}
                    />
                  );
                }
              }}
            />
          </>
        ) : (
          <ErrorMessage
            errorMessage={props.errorMessage}
            type={props.type}
            handleRemoveAllSelections={
              props.errorMessageShouldAllowClear
                ? handleRemoveAllSelections
                : null
            }
          />
        )}
      </div>
    </div>
  );
};
