import React, { useState } from "react";
import { CurrencyInput, QuickInput } from "components/CurrencyInput";
import { useBetslip, useFiatCurrency, useIsLoggedIn, useSelector } from "hooks";
import { getCurrencySymbol } from "common";
import cx from "classnames";
import BigNumber from "bignumber.js";
import { selectUsedPromoAmount } from "sections/Betting/Betslip/betslipSlice";
import { ReactComponent as ClearIcon } from "components/assets/close.svg";

import * as styles from "./Stake.module.scss";
import { useRemainingPromoBalance } from "../hooks/useRemainingPromoBalance";

type StakeProps = {
  amount?: number;
  onChange: (stake: number) => void;
  isPromo?: boolean;
  isActive: boolean;
  onBlur?: () => void;
  quickInputPosition?: "left" | "middle" | "right";
  // this component can calculate remaining promo balance on its own
  // but in some cases we might want to override it if component represents a fraction of stake (e.g. combos)
  remainingPromoBalance?: number;
};

const Stake = ({
  amount,
  onChange,
  isPromo = false,
  isActive,
  onBlur,
  quickInputPosition = "left",
  remainingPromoBalance: remainingPromoBalanceProp,
}: StakeProps) => {
  const {
    props: {
      availableBalance,
      betslipIsSubmitting,
      betslipIsReviewingMessages,
    },
  } = useBetslip();
  const fiatCurrency = useFiatCurrency();
  const currencySymbol = getCurrencySymbol(fiatCurrency);
  const isLoggedIn = useIsLoggedIn();

  const [hasFocus, setHasFocus] = useState(false);

  const shouldBeDisabled =
    betslipIsSubmitting || betslipIsReviewingMessages || !isActive;

  const handleChange = (value: number) => {
    onChange(new BigNumber(amount).plus(value).toNumber());
  };

  const remainingPromoBalance = useRemainingPromoBalance();

  return (
    <div>
      <CurrencyInput
        containerClassName={styles.container}
        inputClassName={styles.input}
        wrapperClassName={cx(styles.wrapper, {
          [styles.disabled]: shouldBeDisabled,
        })}
        currency={fiatCurrency}
        onChange={(value) => onChange(value)}
        value={amount}
        onBlur={() => {
          setHasFocus(false);
          if (onBlur) {
            onBlur();
          }
        }}
        onFocus={() => setHasFocus(true)}
        disabled={shouldBeDisabled}
        renderClearIcon={(props) => (
          <span className={styles.clear} {...props}>
            <ClearIcon />
          </span>
        )}
        iconContainerClassName={styles.iconContainer}
      />
      <QuickInput.Root
        isVisible={hasFocus && !shouldBeDisabled}
        className={cx(styles.quickInput, {
          [styles.middle]: quickInputPosition === "middle",
          [styles.right]: quickInputPosition === "right",
        })}
        currencySymbol={currencySymbol}
        size={"sm"}
      >
        <QuickInput.Option onChange={handleChange} value={5} />
        <QuickInput.Option onChange={handleChange} value={10} />
        <QuickInput.Option onChange={handleChange} value={50} />
        <QuickInput.Option onChange={handleChange} value={100} />
        {isLoggedIn && availableBalance > 0 && (
          <QuickInput.Option
            onChange={(value) => onChange(value)}
            value={
              isPromo
                ? (remainingPromoBalanceProp ?? remainingPromoBalance) / 100
                : availableBalance / 100
            }
            label={`Bal`}
          />
        )}
      </QuickInput.Root>
    </div>
  );
};

export { Stake };
