import cx from "classnames";
import { constructCurrency } from "common";
import { FirebaseContext } from "context/Firebase";
import { useFiatCurrency } from "hooks";
import { BetOdds } from "library";
import React, { type PropsWithChildren, useContext } from "react";
import type { OddsFormat } from "types/BetTypes";
import type { BetEntry } from "sections/Entries/types";
import { BetSummaryContext, useBetSummaryContext } from "./BetSummaryContext";
import { ShareEntryAction } from "./share/ShareEntryAction";
import { useShareEntryModal } from "./share/useShareEntryModal";
import { ReactComponent as ShareIcon } from "components/assets/share.svg";

import * as styles from "./Summary.module.scss";

type BetSummaryProps = PropsWithChildren<{
  entry: BetEntry;
  isCompact: boolean;
  isWon: boolean;
}>;

type BetStakeProps = {
  separator?: React.ReactNode;
  omitStakeValue?: boolean;
  label?: string;
};

type BetOddsSummaryProps = {
  separator?: React.ReactNode;
};

type BetStakePerComboProps = {
  separator?: React.ReactNode;
  omitStakeValue?: boolean;
};

type BetStakeHiddenProps = {
  label?: string;
};

export const BetSummary = ({
  entry,
  isCompact,
  isWon,
  children,
}: BetSummaryProps) => {
  const currency = useFiatCurrency();
  const {
    userPreferences: { oddsFormat },
  } = useContext(FirebaseContext);

  return (
    <BetSummaryContext.Provider value={{ entry, currency, oddsFormat }}>
      <div
        className={cx(styles.summary, {
          [styles.isWon]: isWon,
          [styles.isCompact]: isCompact,
        })}
      >
        {children}
      </div>
    </BetSummaryContext.Provider>
  );
};

export const BetStake = ({
  separator,
  omitStakeValue,
  label,
}: BetStakeProps) => {
  const {
    entry: { isPromo, stake },
    currency,
  } = useBetSummaryContext();

  if (omitStakeValue) return <BetStakeHidden label={label ?? "Stake"} />;

  return (
    <div className={cx({ [styles.isPromo]: isPromo })}>
      <span className={styles.label}>{label ?? "Stake"}</span>
      <span className={styles.value}>
        <div>{constructCurrency(stake, { currency })}</div>

        {isPromo && <div className={styles.bonus}>bonus</div>}
      </span>
      <span className={cx({ [styles.circle]: !!separator })}>{separator}</span>
    </div>
  );
};

export const BetStakeHidden = ({ label }: BetStakeHiddenProps) => {
  return (
    <div>
      <div className={styles.stakeHidden}>
        <div>
          <div className={styles.label}>{label ?? "Stake"}</div>
          <div className={styles.value}>HIDDEN</div>
        </div>
        <div className={styles.emoji}>🤫</div>
      </div>
    </div>
  );
};

export const BetOddsSummary = ({ separator }: BetOddsSummaryProps) => {
  const {
    entry: { status, odds, isSP, picks },
    oddsFormat,
  } = useBetSummaryContext();

  const isMulti = picks.length > 1;
  const spOdds = isMulti ? "-" : "SP";

  return (
    <div className={cx(styles.odds, { [styles.oddsOnly]: !separator })}>
      <span className={styles.label}>Odds</span>
      <span className={styles.value}>
        {isSP ? (
          spOdds
        ) : (
          <BetOdds
            base={odds}
            format={oddsFormat as OddsFormat}
            zero={`1.00`}
            isVoid={status === "VOID"}
          />
        )}
      </span>
      <span className={cx({ [styles.circle]: !!separator })}>{separator}</span>
    </div>
  );
};

export const BetStakePerCombo = ({
  separator,
  omitStakeValue,
}: BetStakePerComboProps) => {
  const {
    entry: { stake, combinationsCount },
  } = useBetSummaryContext();
  const currency = useFiatCurrency();

  const stakePerCombo = stake / combinationsCount;

  if (omitStakeValue) return <BetStakeHidden label={"Stake/Combo"} />;

  return (
    <div className={cx(styles.odds, { [styles.oddsOnly]: !separator })}>
      <span className={styles.label}>Stake/Combo</span>
      <span className={styles.value}>
        <div>{constructCurrency(stakePerCombo, { currency })}</div>
      </span>
      <span className={cx({ [styles.circle]: !!separator })}>{separator}</span>
    </div>
  );
};

export const BetPayout = () => {
  const {
    entry: { isSP, payOut, isCombo, decided },
    currency,
  } = useBetSummaryContext();

  const comboPayout = decided ? constructCurrency(payOut, { currency }) : "TBD";

  return (
    <div>
      <span className={styles.label}>Payout</span>
      <span className={styles.value}>
        {isCombo
          ? comboPayout
          : isSP
            ? "N/A"
            : constructCurrency(payOut, { currency })}
      </span>
    </div>
  );
};

export const BetSummaryAction = () => {
  const { entry } = useBetSummaryContext();
  const [_, getModalProps] = useShareEntryModal();

  return (
    <ShareEntryAction
      {...getModalProps({
        referenceId: entry.referenceId,
        className: styles.action,
        icon: <ShareIcon />,
        entry: [entry, false],
      })}
    />
  );
};
