import * as uuid from "uuid";
import type { ToggleSelectionPayload } from "hooks/firestore/betting/types";
import type {
  Competitor,
  EventMarket,
  Event as EventType,
} from "hooks/firestore/betting/useBetting";
import type {
  RaceMarketsType,
  RaceEventType,
  RaceCompetitorType,
} from "sections/Betting/Race/hooks/RacingTypes";
import type { MarketStatus } from "types/BetTypes";

import {
  mapRaceToEvent,
  sameRaceMultiLabelMap,
} from "utilities/racingUtilities";

const racingConfig = {
  eventMapper: mapRaceToEvent,
  labelMapper: sameRaceMultiLabelMap,
  pickMapper: ({
    markets,
    odds,
    event,
    selections,
    competitors,
  }: {
    markets: RaceMarketsType[];
    odds: number;
    event: RaceEventType;
    selections: string[];
    competitors: RaceCompetitorType[];
  }): ToggleSelectionPayload => ({
    type: "race",
    outcomeId: uuid.v4(),
    sourceId: event.id,
    odds,
    eventId: event.id,
    stake: 0,
    subSelections: selections.map((x) => {
      const market = markets.find((market) => market.outcomes[x]);
      const competitor = competitors.find(
        (competitor) => competitor.id === market.outcomes[x].competitorId,
      );

      const startingPosition =
        competitor?.startingPosition && event.sport !== "GREYHOUNDS"
          ? ` (${competitor?.startingPosition})`
          : "";
      return {
        outcomeId: x,
        marketId: market?.id,
        name: `${competitor?.number}. ${competitor?.name} ${startingPosition}`,
        marketName: market?.name,
        marketStatus: market?.status,
      };
    }),
  }),
};

export const complexBetConfig = {
  srm: racingConfig,
  exotic: racingConfig,
  sgm: {
    eventMapper: ({ event }: { event: EventType }) => event,
    labelMapper: (label: string) => label,
    pickMapper: ({
      odds,
      event,
      selections,
    }: {
      markets: EventMarket[];
      odds: number;
      event: EventType;
      selections: string[];
      competitors: Competitor[];
    }): ToggleSelectionPayload => ({
      type: "match",
      outcomeId: uuid.v4(),
      sourceId: event.eventId,
      odds,
      eventId: event.eventId,
      stake: 0,
      subSelections: selections.map((x) => {
        const market = event.markets.find((market) => market.outcomes[x]);
        return {
          outcomeId: x,
          marketId: market?.id,
          name: market?.outcomes[x]?.name,
          marketName: market?.name,
          marketStatus: market?.status as MarketStatus,
        };
      }),
    }),
  },
};
