import React, { useContext } from "react";
import cx from "classnames";
import { useTitle } from "hooks/graphql/useTitles";
import type { PickType } from "sections/Entries/types";
import { useBoolean } from "usehooks-ts";
import { formatDateTime } from "utilities/datetime";
import { titleCase } from "title-case";
import { navigate } from "library";
import type { OddsFormat } from "types/BetTypes";
import { Clamp } from "components/Clamp";

import { ReactComponent as SGMIcon } from "components/layout/assets/icon-sgm.svg";
import { ReactComponent as SRMIcon } from "components/layout/assets/icon-srm.svg";
import { ReactComponent as SRMTitleIcon } from "components/layout/assets/srm-title.svg";
import { ReactComponent as SGMTitleIcon } from "components/layout/assets/sgm-title.svg";
import { TitleIcon } from "components/TitleIcon";

import { FirebaseContext } from "context/Firebase";
import { Outcome } from "./Outcome";
import { PickIcon } from "./PickIcon";
import { Drawer } from "../../../Drawer/Drawer";
import { DrawerLineItem } from "./DrawerLineItem";
import { isAfter, subDays } from "date-fns";
import { Countdown } from "components/layout/Countdown";
import { BetOddsWithDeductions } from "components/layout/BetOddsWithDeductions";
import { LiveClampWithStream } from "components/layout/Live";
import { MoreInfo } from "components/MoreInfo";
import { ShareAddToBetslip } from "./ShareAddToBetslip";

import * as styles from "./BetPick.module.scss";

type BetPickProps = {
  pick: PickType;
  entryId: string;
  isFirst: boolean;
  isLast: boolean;
  isWon: boolean;
  isDecided: boolean;
  isShare?: boolean;
  isPreview?: boolean;
  onPickAddedToBetslip?: () => void;
  isCombo?: boolean;
};

const BetPick = ({
  pick,
  entryId,
  isFirst,
  isLast,
  isWon,
  isDecided,
  isShare = false,
  isPreview,
  onPickAddedToBetslip,
  isCombo,
}: BetPickProps) => {
  const {
    userPreferences: { oddsFormat },
  } = useContext(FirebaseContext);

  const isVoid = pick.statusText === "VOID";
  const hasSettled =
    pick.marketStatus === "SETTLED" || pick.statusText === "WIN";
  const shouldShowCountdown = !pick.statusText;
  const isLive = pick.statusText === "LIVE";
  const legCount = Object.keys(pick.outcomes).length;

  const { value: isExpanded, toggle: toggleExpanded } = useBoolean(false);
  const title = useTitle(pick.sport);

  const teamNames = pick.teamNames ? (
    <>
      {pick.teamNames[0]} <span className={styles.vs}>vs</span>{" "}
      {pick.teamNames[1]}
    </>
  ) : (
    titleCase(pick.eventName.toLowerCase())
  );

  const isMatch = pick.eventType === "MATCH";
  const isRace = pick.eventType === "RACE";

  const isSRM = pick.isMultiOutcome && !isMatch;
  const isSGM = pick.isMultiOutcome && isMatch;

  const multiOutcomeIcon = isSRM ? <SRMIcon /> : isSGM ? <SGMIcon /> : null;

  const titleIcon = isSRM ? (
    <SRMTitleIcon />
  ) : isSGM ? (
    <SGMTitleIcon />
  ) : (
    <TitleIcon title={title} svg />
  );

  // if entire entry is decided use it's state to determine if it's won otherwise fallback to pick state
  const hasPickWon = pick.statusText === "WIN";
  const hasEntryWon = isDecided ? isWon : hasPickWon;
  const isWonAndDecided = isCombo ? hasPickWon : hasEntryWon;

  // we don't want to navigate to match pages if match is older than 90days
  const isClickable =
    !!pick.url && isAfter(pick.scheduledStartTime, subDays(new Date(), 90));

  return (
    <>
      <div
        className={cx(styles.pick, {
          [styles.isFirst]: isFirst,
          [styles.isLast]: isLast,
          [styles.isWon]: isWonAndDecided,
          [styles.isVoid]: isVoid,
          [styles.isCombo]: !!isCombo,
        })}
      >
        <div className={styles.iconAndDescription}>
          <div className={styles.icon}>{titleIcon}</div>
          <div
            className={cx(styles.info, {
              [styles.clickable]: isClickable,
            })}
            onClick={() => {
              if (isClickable) {
                navigate(pick.url);
              }
            }}
          >
            <span className={styles.outcomeName}>
              {pick.isMultiOutcome ? (
                <span className={styles.teamNames}>
                  {isSRM ? pick.parentName : teamNames}
                </span>
              ) : (
                <>
                  <PickIcon className={styles.pickIcon} pick={pick} />{" "}
                  {pick.outcomeName}
                </>
              )}
            </span>
            {pick.isMultiOutcome ? (
              <>
                <span className={styles.marketName}>
                  {title?.name} {pick.matchMode}
                </span>
                <span className={styles.odds}>
                  {isSRM ? teamNames : pick.parentName}
                </span>
              </>
            ) : (
              <>
                <span className={styles.marketName}>{pick.marketName}</span>
                <span className={styles.odds}>
                  {pick.isSP ? (
                    "@ Starting Price"
                  ) : (
                    <BetOddsWithDeductions
                      prefix={"@"}
                      base={pick.odds}
                      format={oddsFormat as OddsFormat}
                      settlementOdds={pick.settlementOdds}
                      deadHeatFactor={pick.deadHeatFactor}
                      deductions={pick.deductions}
                      isVoid={isVoid}
                      pickUrl={pick.url}
                    />
                  )}
                </span>
              </>
            )}
          </div>

          <div className={styles.clamp}>
            {shouldShowCountdown && (
              <Countdown
                className={cx({
                  [styles.noMouseEvents]: isPreview,
                })}
                scheduledStartTime={pick.scheduledStartTime}
                pick={pick}
              />
            )}
            {!hasSettled && (
              <LiveClampWithStream
                className={cx({
                  [styles.noMouseEvents]: isPreview,
                })}
                isLive={isLive}
                pick={pick}
              />
            )}
            {(isVoid || hasSettled) && pick.statusText && (
              <Clamp variant={pick.statusVariant as any}>
                {isRace && isVoid && !isSRM && !isSGM
                  ? "SCRATCHED"
                  : pick.statusText}
              </Clamp>
            )}
            <div className={styles.caret}>
              <MoreInfo isExpanded={isExpanded} onClick={toggleExpanded} />
            </div>
          </div>
        </div>

        <Drawer isOpen={isExpanded}>
          <div className={styles.drawer}>
            <DrawerLineItem
              label={`Time`}
              value={formatDateTime(
                pick.scheduledStartTime,
                "do MMM, yy @ HH:mm",
              )}
            />

            {isMatch ? (
              <DrawerLineItem label={`Match`} value={teamNames} />
            ) : (
              <DrawerLineItem
                label={`Event`}
                value={titleCase(pick.eventName.toLowerCase())}
              />
            )}
            {pick.parentName && (
              <DrawerLineItem label={``} value={pick.parentName} />
            )}
            {!isShare && <DrawerLineItem label={`Entry Id`} value={entryId} />}
          </div>
        </Drawer>

        {pick.isMultiOutcome && (
          <>
            <div
              className={cx(styles.multiOutcomeOddsContainer, {
                [styles.isWon]: isWonAndDecided,
              })}
            >
              <div className={styles.oddsAndIcon}>
                <div className={styles.icon}>{multiOutcomeIcon}</div>
                <div className={styles.odds}>
                  <span>{legCount} legs </span>
                  <BetOddsWithDeductions
                    prefix={"@"}
                    base={pick.odds}
                    format={oddsFormat as OddsFormat}
                    settlementOdds={pick.settlementOdds}
                    deadHeatFactor={pick.deadHeatFactor}
                    deductions={pick.deductions}
                    isVoid={isVoid}
                    pickUrl={pick.url}
                  />
                </div>
              </div>
            </div>
            {Object.values(pick.outcomes).map((outcome, index) => (
              <Outcome
                key={outcome.outcomeName}
                index={index}
                outcome={outcome}
                sport={pick.sport}
                silksUrl={pick.silksUrl}
                isWon={isWonAndDecided}
                isRace={isRace}
                withStream={!isSGM && !isSRM}
                pick={pick}
              />
            ))}
          </>
        )}
      </div>
      {isShare && (
        <ShareAddToBetslip
          pick={pick}
          onPickAddedToBetslip={onPickAddedToBetslip}
        />
      )}
    </>
  );
};

export { BetPick };
